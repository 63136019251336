import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Clock, Video, Menu, X, User, CreditCard, Calendar, RepeatIcon, Settings, HelpCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ZymifyPlatform = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(2024, 6, 22));
  const [selectedTime, setSelectedTime] = useState(null);
  const [duration, setDuration] = useState('30 min');
  const [calendarType, setCalendarType] = useState('');
  const [zoomConnected, setZoomConnected] = useState(false);

  const navigate = useNavigate();

  const timeSlots = ['10:00am', '11:00am', '1:00pm', '2:30pm', '4:00pm'];
  const durationOptions = ['30 min', '45 min', '1 hour', 'Custom'];

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
  };

  const menuItems = [
    { icon: User, label: 'My Profile' },
    { icon: CreditCard, label: 'Manage Subscription' },
    { icon: Calendar, label: 'Planned Sessions' },
    { icon: RepeatIcon, label: 'Auto-Repeat' },
    { icon: Settings, label: 'Modifications' },
    { icon: CreditCard, label: 'Billing and Support' },
    { icon: HelpCircle, label: 'Help' },
  ];

  const handleLogout = () => {
    // Here you would typically handle the logout logic
    console.log('Logging out');
    navigate('/login');
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className={`bg-gray-800 text-white w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out`}>
        <button onClick={() => setIsMenuOpen(false)} className="absolute top-5 right-5 md:hidden">
          <X size={24} />
        </button>
        <div className="flex items-center space-x-2 px-4">
          <div className="w-10 h-10 bg-orange-500 rounded-lg"></div>
          <span className="text-2xl font-extrabold">Zymify</span>
        </div>
        <nav>
          {menuItems.map((item, index) => (
            <a key={index} href="#" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white">
              <item.icon size={20} className="inline-block mr-2" />
              {item.label}
            </a>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Navbar */}
        <header className="bg-white shadow-md">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
              <Menu size={24} />
            </button>
            <h1 className="text-2xl font-semibold text-gray-900">Schedule Assistant</h1>
            <div className="flex items-center">
              <img src="/api/placeholder/32/32" alt="Profile" className="w-8 h-8 rounded-full" />
              <span className="ml-2 text-gray-700">Sample User</span>
              <button 
                onClick={handleLogout}
                className="ml-4 bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
              >
                Logout
              </button>
            </div>
          </div>
        </header>

        {/* Schedule Assistant Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <div className="border-4 border-dashed border-gray-200 rounded-lg p-4">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/3 pr-6 border-r">
                    {/* Calendar Integration */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2 text-gray-800">Calendar Integration</h3>
                      <select 
                        className="w-full p-2 border rounded bg-gray-100 text-gray-800"
                        value={calendarType}
                        onChange={(e) => setCalendarType(e.target.value)}
                      >
                        <option value="">Select Calendar</option>
                        <option value="google">Google Calendar</option>
                        <option value="apple">Apple Calendar</option>
                        <option value="outlook">Outlook Calendar</option>
                      </select>
                    </div>

                    {/* Zoom Connection */}
                    <div className="mb-4">
                      <button 
                        className={`w-full p-2 rounded ${zoomConnected ? 'bg-green-500' : 'bg-blue-600'} text-white`}
                        onClick={() => setZoomConnected(!zoomConnected)}
                      >
                        {zoomConnected ? 'Zoom Connected' : 'Connect Zoom Account'}
                      </button>
                    </div>

                    {/* Meeting Settings */}
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2 text-gray-800">Meeting Settings</h3>
                      <div className="mb-2">
                        <label className="block text-gray-700 mb-1">Duration</label>
                        <select 
                          className="w-full p-2 border rounded bg-gray-100 text-gray-800"
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                        >
                          {durationOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-2">
                        <label className="block text-gray-700 mb-1">Zoom Settings</label>
                        <div className="flex items-center mb-1">
                          <input type="checkbox" id="waiting-room" className="mr-2" />
                          <label htmlFor="waiting-room" className="text-gray-700">Enable Waiting Room</label>
                        </div>
                        <div className="flex items-center">
                          <input type="checkbox" id="mute-participants" className="mr-2" />
                          <label htmlFor="mute-participants" className="text-gray-700">Mute Participants on Entry</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="w-full md:w-2/3 pl-6 mt-6 md:mt-0">
                    <h3 className="text-xl font-semibold mb-4 text-gray-800">Select a Date & Time</h3>
                    
                    <div className="flex justify-between items-center mb-4">
                      <button className="p-2 rounded-full hover:bg-gray-200">
                        <ChevronLeft size={24} />
                      </button>
                      <span className="text-lg font-semibold text-gray-800">July 2024</span>
                      <button className="p-2 rounded-full hover:bg-gray-200">
                        <ChevronRight size={24} />
                      </button>
                    </div>
                    
                    <div className="grid grid-cols-7 gap-2 mb-6">
                      {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(day => (
                        <div key={day} className="text-center font-semibold text-gray-500">{day}</div>
                      ))}
                      {Array.from({ length: 35 }, (_, i) => (
                        <button
                          key={i}
                          className={`p-2 rounded-full ${i + 1 === 22 ? 'bg-orange-500 text-white' : 'hover:bg-gray-200 text-gray-700'}`}
                          onClick={() => setSelectedDate(new Date(2024, 6, i + 1))}
                        >
                          {i + 1}
                        </button>
                      ))}
                    </div>
                    
                    <p className="text-lg font-semibold mb-4 text-gray-800">{formatDate(selectedDate)}</p>
                    
                    <div className="grid grid-cols-2 gap-4">
                      {timeSlots.map(time => (
                        <button
                          key={time}
                          className={`p-2 rounded ${selectedTime === time ? 'bg-orange-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-800'}`}
                          onClick={() => setSelectedTime(time)}
                        >
                          {time}
                        </button>
                      ))}
                    </div>
                    
                    <div className="mt-6">
                      <button className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600">
                        Confirm
                      </button>
                    </div>
                    
                    <div className="mt-6">
                      <p className="text-gray-600">Time zone</p>
                      <select className="mt-1 block w-full p-2 border rounded bg-gray-100 text-gray-800">
                        <option>Eastern time - US & Canada</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ZymifyPlatform;
